import {defineI18nConfig} from '#i18n';

import ruGlobal from './ru';
import main from './ru/main';
import news from './ru/news';
import blog from './ru/blog';
import autobot from './ru/autobot';
import analyser from './ru/analyser';
import validation from './ru/validation';
import aiReviewResponseGenerator from './ru/aiReviewResponseGenerator';
import signin from './ru/signin';
import signup from './ru/signup';
import contacts from './ru/contacts';
import telegramBot from './ru/telegramBot';
import crm from './ru/crm';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: {
    ru: {
      ...ruGlobal,
      main,
      news,
      blog,
      autobot,
      analyser,
      validation,
      aiReviewResponseGenerator,
      signin,
      signup,
      contacts,
      telegramBot,
      crm,
    },
  },
}));
