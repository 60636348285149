export default {
  intro: {
    title: 'Управление клиентами и продажами',
    titleColored: 'CRM Система Sales Area',
    text: `Управление клиентской базой, лидами и сделками. Эффективный контроль над задачами сотрудников. Статистика
     и основные метрики продаж. CRM Sales Area поможет быстро автоматизировать процессы и превратить каждое касание клиента в прибыль`,
    filledBtnText: 'Зарегистрироваться',
    btnText: 'Оставить заявку',
  },
  info: {
    title: 'Что делает SalesArea лучше, чем 99% других решений на рынке?',
    helpLink: 'Нужна помощь?',
  },
  advantages: {
    title: 'Добейтесь большего с SalesArea',
    cards: {
      crm: {
        title: 'Управление базой клиентов',
        text: 'Классическая модель CRM. Создавайте и конвертируйте лидов в сделки. Управляйте контактами и компаниями',
      },
      marketing: {
        title: 'Многоуровневый планировщик задач',
        text: 'Группируйте и контролируйте задачи сотрудников',
      },
      management: {
        title: 'Статистика и основные метрики продаж',
        text: 'Удобный дашборд с основными показателями, воронками и детальной аналитикой продаж',
      },
      communication: {
        title: 'HR Блок',
        text: 'Управляйте сотрудниками и кандидатами',
      },
    },
  },
};
