export default {
  title: 'Генератор ответов на отзывы с помощью ИИ',
  subtitle: 'Генератор ответа от Sales Area на основе Yandex GPT поможет сформировать ответ на негативный или положительный отзыв покупателя маркетплейсов Wildberries и Ozon.',
  formText: 'Заполните поля и нажмите',
  formTextBold: 'Сгенерировать текст ответа',
  trial: 'Пробный бесплатный период 1 день',
  offerText: 'Генератор ответов без ограничений',
  btnText: 'Подключить Генератор ответов',
  btnOrderText: 'Оставить заявку',
  form: {
    customerName: 'Введите имя покупателя',
    productDesc: 'Введите наименование товара',
    text: 'Введите текст отзыва',
    ratingText: 'Укажите оценку в отзыве',
    answerPlaceholder: 'Здесь будет текст ответа',
    submitBtnText: 'Сгенерировать текст ответа',
    title: 'Попробуйте сгенерировать ответ онлайн бесплатно',
  },
  validation: {
    rating: {
      required: 'Необходимо указать рейтинг товара',
      minValue: 'Укажите оценку товара, минимум - 1 звезда',
    },
    customerName: {
      required: 'Необходимо указать имя покупателя',
    },
    productDesc: {
      required: 'Необходимо указать наименование товара',
    },
    text: {
      required: 'Необходимо указать текст отзыва',
    },
  },
  infoTg: {
    textInc: 'Подключите ',
    textLink: 'Телеграм-бот ',
    text: {
      str1: 'Sales Area, и больше не нужно будет вручную заполнять поля.',
      str2: 'Отзывы ваших магазинов на Wildberries будут приходить прямо в telegram, где вы сможете отвечать на них, генерируя ответы с помощью нейросети',
      strBold: 'БЕЗЛИМИТНО.',
      str3: 'Подробнее о Телеграм-боте смотрите',
      strLink: 'здесь.',
    },
    btnText: 'Попробовать Телеграм-бот бесплатно',
    attentionText: 'При увеличении нагрузки на бесплатный сервис генерации ответов количество доступных ответов в сутки может быть ограничено автоматически.',
  },
  modalInfo: {
    text1: 'Чтобы продолжить генерировать тексты ответов, зарегистрируйтесь и получите доступ',
    text2: 'к личному кабинету Sales Area',
    button: 'Зарегистрироваться',
  },
};
