export default {
  intro: {
    title:
      'Забудьте об отзывах покупателей - поручите ответы боту. Настройте обработку отзывов с помощью собственных шаблонов или используйте нейросеть.',
  },
  advantages: {
    title: 'Возможности автоответчика Sales Area',
    subtitle: `Работая с отзывами, компания показывает свою заботу о клиентах и готовность к диалогу.
     Это укрепляет доверие потребителей, а также позволяет корректировать недостатки и улучшать качество товаров или услуг.`,
    cards: {
      system: {
        title: 'Работа в личном кабинете через веб-интерфейс',
      },
      support: {
        title: 'Гибкая настройка сценариев автоответов',
      },
      security: {
        title: 'Возможность генерации ответов с помощью ИИ',
      },
      settings: {
        title: 'Телеграм бот для просмотра отзывов на бегу',
      },
      demo: {
        title: 'Пробный бесплатный период 7 дней',
      },
      help: {
        title: 'Оперативная техническая поддержка',
      },
    },
  },
  acquaintance: {
    subtitle: 'Мы собрали самые важные функции автоответчика и поместили их в удобный интерфейс!',
  },
  comparison: {
    title: 'Кому лучше поручить ответы на отзывы покупателей?',
    autobot: {
      title: 'Автобот SalesArea',
    },
    employee: {
      title: 'Сотрудник',
    },
  },
  marketplaces: {
    title: 'Wildberries и Ozon  в едином Личном Кабинете!',
    wb: {
      title: 'Wildberries',
    },
    ozon: {
      title: 'Ozon',
    },
  },
  conditions: {
    title: 'Гибкие условия применения автоответов',
    buttonText: 'Запросить демо',
  },
  answerExamples: {
    subtitle: `Отвечая на отзывы покупателей, компания показывает свою заботу о клиентах и готовность к диалогу.
     Это укрепляет доверие потребителей, а также позволяет корректировать недостатки и улучшать качество товаров или услуг.`,
  },
  ai: {
    title: 'Отвечайте на отзывы с помощью ИИ',
    text: 'Теперь не обязательно каждый раз придумывать новый ответ на отзыв самому. Нейросеть на основе Yandex GPT может сделать это за вас. Вы можете отредактировать предложенный текст или отправить ответ без изменений.',
    textTry: 'Познакомиться с примерами генерации ответов на отзывы с помощью нейросети можно в нашем бесплатном онлайн генераторе:',
    buttonText: 'Попробовать онлайн генератор',
  },
  prices: {
    title: 'Гибкие цены! Безлимитное количество ответов в любом тарифе!',
  },
  aiAnswer: {
    title: 'Ответы на отзывы с помощью ИИ',
    text: 'Теперь не обязательно каждый раз придумывать новый ответ на отзыв самому. Нейросеть на основе Yandex GPT может сделать это за вас. Вы можете отредактировать предложенный текст или отправить ответ без изменений.',
    buttonText: 'Запросить демо',
  },
  demoForm: {
    title: 'Покажем, как правильно настроить автоответы, и ответим на все имеющиеся вопросы! Запишитесь на персональную демонстрацию.',
  },
};
