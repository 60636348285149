export default {
  intro: {
    subtitle: 'Определите, какие ваши товары продаются лучше и показывают рост продаж. Поддержите продажи эффективной логистикой и правильным распределением поставок по складам Wildberries.',
  },
  advantages: {
    title: 'Аналитика от SalesArea',
    cards: {
      system: {
        title: 'Показатели продаж в денежном и количественном выражении',
      },
      support: {
        title: 'Отображение общей и индивидуальной динамики продаж для каждого товара',
      },
      security: {
        title: 'Анализ продаж к предыдущему периоду. Определение трендов',
      },
      settings: {
        title: 'Планирование поставок по складам с учетом темпа продаж и логистики',
      },
    },
  },

};
