export default {
  intro: {
    title: 'Телеграм Бот от Sales Area - отвечайте на отзывы легко и быстро!',
    text: 'SalesAreaBot поможет мгновенно узнавать о новых отзывах вашего магазина Wildberries и легко отвечать на них с помощью нейросети.',
    priceText: 'Пробный бесплатный период 7 дней',
    buttonText: 'Попробовать Телеграм Бот',
  },
  advantages: {
    title: 'Возможности',
    subtitle: `В ТГ боте от Sales Area можно настроить уведомления о новых отзывах на ВБ и в несколько кликов отвечать на них прямо из Telegram.
     Вам не потребуется набирать текст ответа вручную: его можно сгенерировать с помощью ИИ и отредактировать перед отправкой.`,
    cards: {
      notifications: {
        title: 'Уведомления о новых отзывах в Telegram',
      },
      ai: {
        title: 'Генерация ответов с помощью ИИ',
      },
      answers: {
        title: 'Безлимитное количество ответов',
      },
      settings: {
        title: 'Подключение через официальный API Вайлдберриз',
      },
    },
  },
  qr: {
    title: 'Сканируйте QR-код и получите полный доступ на 7 дней',
    text: 'Вам потребуется API токен продавца из личного кабинета Wildberries.',
  },
  details: {
    title: 'Устали отвечать на отзывы вручную?',
    text: 'Подключите автоответы Sales Area! Отзывы будут обработаны автоматически, своевременно, без выходных. Вам нужно лишь  решить, для каких отзывов применять автоответы, а какие обрабатывать вручную.',
    buttonText: 'Подробнее здесь',
  },
};
