export default {
  email: {
    required: 'Необходимо ввести email',
    email: 'Введите корректный email',
  },
  phone: {
    required: 'Необходимо ввести номер телефона',
    numeric: 'Формат номера: +70000000000 ',
  },
  clientName: {
    required: 'Необходимо ввести Ваше имя',
    minLen: 'Имя должно быть больше 2 символов',
  },
  companyName: {
    required: 'Необходимо ввести название компании',
  },
  siteName: {
    required: 'Необходимо ввести название сайта',
  },
  password: {
    required: 'Необходимо указать пароль пользователя',
  },
};
